/* #main-team {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
} */

.owl-theme {
    padding: 20px;

}
/* 
#landing2 {
    min-height: 100%;
    min-height: 100vh;
    width: 100%;
    padding: 80px 0 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    align-content: center;
} */

.card {
    width: 13rem;
    border: none;
    padding: 5px;
    margin-right: 25px;
    background-color: transparent;
    cursor: pointer;
}

.member-name {
    font-family: FiraSans-Medium, SourceSansPro-Semibold;
    font-size: 1rem;
    text-transform: uppercase;
    text-indent: 2px;
    letter-spacing: 2px;
}

.member-position {
    font-family: FiraSans, SourceSansPro;
    font-size: 14px;
    text-indent: 2px;
    letter-spacing: 2px;
}

#sdp-team {
    /* width: 100%; */
    height: 80vh;
    align-content: center;
    vertical-align: middle;
    margin: auto;
    padding: 0;
}

.closeBtn {
    background-color: #F5F5F5;
    border: 0;
    border-bottom: 1px solid #000;
    cursor: pointer;
    font-family: FiraSans-Medium, SourceSansPro-Semibold;
    font-size: 14px;
    text-indent: 3px;
    letter-spacing: 3px;
}

.break {
    display: none;
}

/* TABLET */
@media (max-width: 768px) and (min-width: 426px) {
    /* about ezsofe */
    div.about-ezsofe {
        padding-left: 35px;
    }

    /* optimizing ur capablities */
    div.optimizing {
        padding-left: 35px;
        padding-right: 35px;
    }

    /* the team */
    div.the-team {
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap; */
        padding-left: 35px;
        padding-right: 35px;
    }

    .tile-team {
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap; */
        /* width: 100%; */
        /* padding-left: 30px;
        padding-right: 30px; */
    }

    .staff {
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 30px;
        padding-right: 30px; */
    }

    .about-team-top {
        /* width: 50%; */
    }

    /* STUDENT DEVS */
    div.row-student-devs {
        padding-left: 35px;
        padding-right: 35px;
    }

    /* .students_columns {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .students {
        width: 100px;
    }
    
    .student-devs {
        width: 100px !important;
    } */
}

@media (max-width: 768px) {
    h5#student_devs_desc {
        font-size: 0rem;
    }
}

@media (max-width: 425px) {
    /* optimizing your capabilities */
    .content-title-light {
        padding-left: 10px;
        width: 100vw
    }

    .client-content-btn {
        padding-left: 30px;
    }
}

@media (max-width: 320px) {
    .break {
        display: initial;
    }
}

/** */
div.about-space {
    height: 52px;
}

div.about-title {
    height: 50px;
}

div.title-border {
    border-bottom: 1px solid black;
}

/** After adjusting Bulma */

div#about-top-spacing {
    height: 20px;
}

div.about-small-spacing {
    height: 50px;
}

