#home-contact section.hero div.hero-body div.react-reveal {
  width: 100%;
}

#home-contact .hero-head h1 {
  font-weight: 600;
}

#home-contact .hero-head h1.subtitle.is-4 {
  margin-bottom: 12px;
}

#home-contact, section#contact {
  background: linear-gradient(whitesmoke, lightgrey);
}

#home-contact section#contact {
  background: none;
}