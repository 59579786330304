#services svg {
  stroke: black;
  stroke-width: 8;
  fill: none;
  width: fit-content;
  max-width: 75%;
}

@media (min-width: 769px) {
  .column-services {
    text-align: left;
  }
}

@media (max-width: 768px) {
  #services svg {
    max-width: 25%;
  }

  ul.column-services {
    text-align: center;
  }

  ul.column-services li {
    width: 70%;
    margin: auto;
  }
}

@media (max-width: 768px) and (min-width: 426px) {

  /* top 3 */
  .individual {
    width: 30vw !important;
    height: auto;
    padding-left: 20px;
  }

  /* bottom 2 */
  .indiv {
    width: 50vw !important;
    padding-left: 40px;
  }

  .all {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 10px;
  }

  #mobile, #maintenance {
    margin: auto;
    padding-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px !important;
  }
}

@media (max-width: 375px) {
  .all {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 

  }
  
  .individual {
    width: 100vw;
    height: auto;
    padding-right: 30px;
  }

  .indiv {
    /* width: 100vw; */
  }

  .service_name {
    padding-left: 10px;
    padding-right: 0px !important;
  }
}

div.columns.is-multiline > div.column > div.box {
  height: 100%;
}

@media (max-width: 320px) {
  .service_name {
    width: 90vw;
  }

  .individual {
    /* width: 65vw; */
    padding-left: 25px;
  }
}