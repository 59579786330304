#workModal {
    width: 100%;
    margin-bottom: 100px;
    position: absolute;
    z-index: 9999;
    background-color: #F5F5F5
}

.work-title-header {
    font-family: FiraSans-Medium, SourceSansPro-Semibold;
    font-size: 24px;
    letter-spacing: 3px; 
    padding: 1.5rem;
  }
  
#figure-img {
    display: flex;
    width: 100%;
    height: 75vh;
    background-color: #fff;
    justify-content: center;
}
  
#figure-img > img {
    min-height: 100%;
    object-fit: contain;
}
  
.work-title {
    font-family: FiraSans-Medium, SourceSansPro-Semibold;
    font-size: 24px;
    letter-spacing: 3px; 
    margin-top: 1rem;
}

.work-subtitle {
    font-family: FiraSans, SourceSansPro;
    font-size: 20px;
    letter-spacing: 3px; 
}

.work-details {
    font-family: FiraSans, SourceSansPro;
    font-size: 16px;
    margin-top: 2.5rem;
    margin-bottom: 5rem;
}
#imagegallery1{
    width: 100%;
}
@media (max-width:600px)
{
    #imagegallery1{
        height:200px;
    }
}