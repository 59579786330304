.workImg {
    cursor: pointer;
    border: 0.5rem solid transparent;
    justify-self: center;
}

.workImg:hover {
    border: 0.3rem solid transparent;
}
p{
    font-size: 20px;
    margin: 0 0 0 10px;
}