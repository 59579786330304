#home-approach .column .box {
  height: 450px;
  
}

/***/

div#plan-icon svg {
  stroke-width: 1;
}

div.wf_svg svg {
  
  overflow: visible;
  stroke-width: 8;
  max-width: 56%;
  margin: auto;
 
}

.wf_image_caption {
  margin-top: 20px;
}

.wf_stage_row {
  /* border: 1px solid magenta; */
  justify-content: space-around;
  margin: 20px 0 0 0;
  width: 99%;
  text-align: center;
  /* border: 1px solid green; */
}

div#approach-wrapper {
  
  max-width: 80%;
  margin: 0 auto 80px auto;
  /* padding: 10px 20px 0 20px; */
  margin:auto;

}




/** Mobile-view-only rules - Approach */
@media (max-width: 768px) and (min-width: 426px) {
  div.wf_svg svg {
    max-width: 32%;
  }

  div#approach-wrapper {
    max-width: unset;
    margin: auto;
    padding: 0;
  }

  #row {
    display: flex;
    flex-direction: row;
    width: 90vw;
    height: auto;
  }

  .stages {
    width: 20px;
  }
}

.text-center {
  text-align: center;
}

/** Titles */
.workflow-title {
  font-family: FiraSans, SourceSansPro;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0px;
  padding: 5px;
  border: 1px solid #000;
  width: 99%
}

/* ====================== */
/* ====================== */

/* CSS Mobile - Lincoln*/
@media (max-width: 1023px)
{
    .desktop{
        width: 99%;
    }
    .none{
        display: none
    }
    .wf_svg{
        stroke: black;
        stroke-width: 1;
        fill: none;
        text-align: center;
    }
    div#test-svg > svg {
        stroke-width: 1;    /* Too thick by default; set to 1 */
   }
   .workflow-para {
        font-family: FiraSans, SourceSansPro-Light;
        font-size: 14px;
        margin-bottom: 15px;
        width: 99%;
    }

    .wf_image_caption{
        text-align: center;
    }
    .colorline{
        display: block;
        margin: auto;
    }
}

/* CSS Desktop - Lincoln*/
@media(min-width: 1024px){
    .mobile{
        display: none;
    }
    .desktop{
        width: 99%;
    }
    .tag{
        width: 100%;
        height: 160px;
    }
    .wf_svg{
        stroke: black;
        stroke-width: 1;
        fill: none;
        text-align: center;
        height:90px;
    }
    .arrow{
        display: flex;
        justify-content: flex-end;
    }
    .wf_arrow > svg {
        width: 20px;
        height: 120px;
        margin: 10px 0px !important;
        /* display: flex; */
        padding-top: 10%;
        justify-content: flex-end;
    }
    div#test-svg > svg {
        stroke-width: 1;    /* Too thick by default; set to 1 */
   }
   .workflow-para {
        font-family: FiraSans, SourceSansPro-Light;
        font-size: 16px;
        margin-bottom: 10px;
        width: 99%;
    }
    .wf_branches{
        height: 30px;
        margin-left: 49px;
        margin-bottom: 50px;
        stroke-width: 1 !important;
    }

    .wf_image_caption{
        text-align: center;
    }
    
    .colorline{
        display: block;
        margin: auto;
    }
}

.wf_desc_row > p {
  margin: 0;
}
.arrows{
  height:3.25em;
  
}

@media (max-width: 1024px) and (min-width: 769px) {
  .wf_stage_row {
    width: 15vw;
  }
}

@media (max-width: 768px) and (min-width: 426px) {

  #approach svg {
    max-width: 30%;
  }

  .stages {
    width: 25vw;
    height: auto;
  }

  .wf_stage_row {
    width: 50vw;
    height: auto;
  }
  #approach {
    width: 100vw;
    height: auto;
  }

  .heading {
    width: 26vw ;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 425px) {
  div.wf_image svg {
    max-width: 32%;
  }
}

@media (max-width: 375px) and (min-width: 321px) {
  .stages {
    width: 100vw;
    height: auto;
    padding-right: 100px
  }

  .heading {
    padding-right: 70px
  }

  .description {
    padding-left: 15px;
  }
}

@media (max-width: 320px) {
  .heading {
    width: 60vw;
    padding-left:25px;
  }

  .description {
    /* padding-left: 10px */
  }
}

