@font-face{
    font-family: SourceSansPro-Light;
    src: url(/static/media/SourceSansPro-Light.b2e90cc0.ttf);
}

@font-face{
    font-family: SourceSansPro;
    src: url(/static/media/SourceSansPro-Regular.ba6cad25.ttf);
}

@font-face{
    font-family: SourceSansPro-Semibold;
    src: url(/static/media/SourceSansPro-Semibold.52984b3a.ttf);
}

@font-face{
    font-family: SourceSansPro-Bold;
    src: url(/static/media/SourceSansPro-Bold.5c6c404e.ttf);
}

@font-face{
    font-family: SourceSansPro-Black;
    src: url(/static/media/SourceSansPro-Black.87dc85e3.ttf);
}

@font-face{
    font-family: FiraSans-Light;
    src: url(/static/media/FiraSans-Light.bb29fd92.ttf);
}

@font-face{
    font-family: FiraSans;
    src: url(/static/media/FiraSans-Regular.8a882846.ttf);
}

@font-face{
    font-family: FiraSans-Medium;
    src: url(/static/media/FiraSans-Medium.12a58b23.ttf);
}

@font-face{
    font-family: FiraSans-Semibold;
    src: url(/static/media/FiraSans-SemiBold.66188dee.ttf);
}

@font-face{
    font-family: FiraSans-Bold;
    src: url(/static/media/FiraSans-Bold.eca2c47a.ttf);
}

@font-face{
    font-family: FiraSans-Black;
    src: url(/static/media/FiraSans-Black.fef33359.ttf);
}
html {
  height: 100%;
  width: 100%;
}

body {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: FiraSans, SourceSansPro, sans-serif !important;
  background-color: "light";
  overflow-x: hidden;
}
/* 
@media (max-width: 768px) {
  .hero-body {
    padding: 10px !important;
  }
} */

/* .navbar{
  height: 40px;
} */


.navbar::after {
  content: "";
  display: block;
  height: 3px;
  background: #6381bb; /* Old browsers */ /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #6381bb 0%,
    #c9527a 50%,
    #954791 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #6381bb 0%,
    #c9527a 50%,
    #954791 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6381bb', endColorstr='#954791',GradientType=1 ); /* IE6-9 */
}
.hero-head{
  
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 9999;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.navbar-item img {
  
  /* margin-left:-20%; */
 
  /* max-height: auto; */
  
}
.navbar-item{
  padding:0px 40px;
  
}
.underline1:hover{
  color: #4a4a4a;
 border-bottom:2px #4a4a4a solid;
}

/* .navbar-item > img {
  
} */
.dropdown {
  box-shadow: 0 0 8px #777;
  display: none ;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 1000;
}

.is-open {
  display: block;
}

@media (max-width: 768px){
  .navbar-item {
    padding-left: 20px;
  }
}

@media (max-width: 425px){
  .navbar-item {
    padding-left: 10px;
  }
}
#landing {
  background-image: -webkit-linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(/static/media/bg-stock.93a14fbe.jpg);
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(/static/media/bg-stock.93a14fbe.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-size: cover;
  -webkit-align-content: center;
          align-content: center;
  overflow: hidden;
}

@media (max-width: 768px) {
  #landing h1 {
    font-size: 1.5em;
  }
}

@media (max-width: 375px) {
   .title {
    width:100vw;
  }
}


.slide .hero-body {
  padding: 10px !important;
  
}

@media (max-width: 768px) {
  .slide h1 {
    letter-spacing: 5px;
  }
}

.card {
    width: 13rem;
    border: none;
    padding: 5px;
    margin-right: 25px;
    background-color: transparent;
    cursor: pointer;
}

.avatar {
    max-height: 200px;
    border-radius: 50%;
    border:0px;
}
.about-content-item {
  margin-left: 40px;
  padding-left: 20px;
  padding-right: 25px;
  border-left: 1px solid #333;
}

#services svg {
  stroke: black;
  stroke-width: 8;
  fill: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 75%;
}

@media (min-width: 769px) {
  .column-services {
    text-align: left;
  }
}

@media (max-width: 768px) {
  #services svg {
    max-width: 25%;
  }

  ul.column-services {
    text-align: center;
  }

  ul.column-services li {
    width: 70%;
    margin: auto;
  }
}

@media (max-width: 768px) and (min-width: 426px) {

  /* top 3 */
  .individual {
    width: 30vw !important;
    height: auto;
    padding-left: 20px;
  }

  /* bottom 2 */
  .indiv {
    width: 50vw !important;
    padding-left: 40px;
  }

  .all {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-right: 10px;
  }

  #mobile, #maintenance {
    margin: auto;
    padding-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px !important;
  }
}

@media (max-width: 375px) {
  .all {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; 

  }
  
  .individual {
    width: 100vw;
    height: auto;
    padding-right: 30px;
  }

  .indiv {
    /* width: 100vw; */
  }

  .service_name {
    padding-left: 10px;
    padding-right: 0px !important;
  }
}

div.columns.is-multiline > div.column > div.box {
  height: 100%;
}

@media (max-width: 320px) {
  .service_name {
    width: 90vw;
  }

  .individual {
    /* width: 65vw; */
    padding-left: 25px;
  }
}
#home-approach .column .box {
  height: 450px;
  
}

/***/

div#plan-icon svg {
  stroke-width: 1;
}

div.wf_svg svg {
  
  overflow: visible;
  stroke-width: 8;
  max-width: 56%;
  margin: auto;
 
}

.wf_image_caption {
  margin-top: 20px;
}

.wf_stage_row {
  /* border: 1px solid magenta; */
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 20px 0 0 0;
  width: 99%;
  text-align: center;
  /* border: 1px solid green; */
}

div#approach-wrapper {
  
  max-width: 80%;
  margin: 0 auto 80px auto;
  /* padding: 10px 20px 0 20px; */
  margin:auto;

}




/** Mobile-view-only rules - Approach */
@media (max-width: 768px) and (min-width: 426px) {
  div.wf_svg svg {
    max-width: 32%;
  }

  div#approach-wrapper {
    max-width: unset;
    margin: auto;
    padding: 0;
  }

  #row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 90vw;
    height: auto;
  }

  .stages {
    width: 20px;
  }
}

.text-center {
  text-align: center;
}

/** Titles */
.workflow-title {
  font-family: FiraSans, SourceSansPro;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0px;
  padding: 5px;
  border: 1px solid #000;
  width: 99%
}

/* ====================== */
/* ====================== */

/* CSS Mobile - Lincoln*/
@media (max-width: 1023px)
{
    .desktop{
        width: 99%;
    }
    .none{
        display: none
    }
    .wf_svg{
        stroke: black;
        stroke-width: 1;
        fill: none;
        text-align: center;
    }
    div#test-svg > svg {
        stroke-width: 1;    /* Too thick by default; set to 1 */
   }
   .workflow-para {
        font-family: FiraSans, SourceSansPro-Light;
        font-size: 14px;
        margin-bottom: 15px;
        width: 99%;
    }

    .wf_image_caption{
        text-align: center;
    }
    .colorline{
        display: block;
        margin: auto;
    }
}

/* CSS Desktop - Lincoln*/
@media(min-width: 1024px){
    .mobile{
        display: none;
    }
    .desktop{
        width: 99%;
    }
    .tag{
        width: 100%;
        height: 160px;
    }
    .wf_svg{
        stroke: black;
        stroke-width: 1;
        fill: none;
        text-align: center;
        height:90px;
    }
    .arrow{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }
    .wf_arrow > svg {
        width: 20px;
        height: 120px;
        margin: 10px 0px !important;
        /* display: flex; */
        padding-top: 10%;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }
    div#test-svg > svg {
        stroke-width: 1;    /* Too thick by default; set to 1 */
   }
   .workflow-para {
        font-family: FiraSans, SourceSansPro-Light;
        font-size: 16px;
        margin-bottom: 10px;
        width: 99%;
    }
    .wf_branches{
        height: 30px;
        margin-left: 49px;
        margin-bottom: 50px;
        stroke-width: 1 !important;
    }

    .wf_image_caption{
        text-align: center;
    }
    
    .colorline{
        display: block;
        margin: auto;
    }
}

.wf_desc_row > p {
  margin: 0;
}
.arrows{
  height:3.25em;
  
}

@media (max-width: 1024px) and (min-width: 769px) {
  .wf_stage_row {
    width: 15vw;
  }
}

@media (max-width: 768px) and (min-width: 426px) {

  #approach svg {
    max-width: 30%;
  }

  .stages {
    width: 25vw;
    height: auto;
  }

  .wf_stage_row {
    width: 50vw;
    height: auto;
  }
  #approach {
    width: 100vw;
    height: auto;
  }

  .heading {
    width: 26vw ;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 425px) {
  div.wf_image svg {
    max-width: 32%;
  }
}

@media (max-width: 375px) and (min-width: 321px) {
  .stages {
    width: 100vw;
    height: auto;
    padding-right: 100px
  }

  .heading {
    padding-right: 70px
  }

  .description {
    padding-left: 15px;
  }
}

@media (max-width: 320px) {
  .heading {
    width: 60vw;
    padding-left:25px;
  }

  .description {
    /* padding-left: 10px */
  }
}


#clients {
    min-height: 100%;
    min-height: 100vh;
    width: 100%;
    padding: 80px 0 0 0;
}

.client-content-item {
    width: 100%;
    border: 0px;
    height: auto;
    overflow: hidden;
    margin-top: 25px;
    margin-bottom: 25px;
    z-index: 1;
}

.client-content-btn {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/***/

#home-works-content {
    margin: auto;
}

#works .hero-body .react-reveal {
    margin: auto;
    vertical-align: middle;
}

#home-works-content .column {
  padding: 20px;  
}


#home-works-content .column img {
  /* display: block; */
  /* margin: auto; */
  max-height: 80px;
}

@media (max-width: 768px) and (min-width: 426px) {
  #column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100vw;
    /* padding-right: 10px; */
  }

  #home-works-content {
    /* padding-right: 10px; */
  }

  .individual {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    /* width: 90vw; */
    height: auto;
  }
  
  .content-title {
    width: 20vw;
    padding-left: 0px !important;
  }

  .image {
    width: 20vw;
  }

  .client-content-btn {
    padding-left: 30px;
    width: 100vw;
  }

  .content-title-light {
    padding-right: 10px;
  }
}

@media (max-width: 425px) {
  .content-title-light {
    padding-right: 10px;
  }

  .client-content-btn {
    padding-right: 30px;
  }
}
.column-testimonial {
  background: rgba(255, 255, 255, 0.8);
}

#testimonials .hero-body .react-reveal {
  margin: auto;
}

#testimonials {
  min-height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 80px 0 0 0;
  opacity: 1;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.35)), url("/static/media/Lukas added to photo yaacoobIbrahim.09827716.png");
  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.35)), url("/static/media/Lukas added to photo yaacoobIbrahim.09827716.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-size: cover;
  -webkit-align-content: center;
          align-content: center;
}

.testimonial-title {
    font-family: FiraSans, SourceSansPro;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 10px 20px 10px 20px;
    border: 1px solid #000;
}

.testimonial-para {
  font-family: FiraSans-Light, SourceSansPro-Light;
  margin-top: 25px;
  padding-left: 25px;
  border-left: 2px solid #000;
}
@media (max-width: 768px) and (min-width: 426px) {
  .box {
    width: 40vw;
  }

  .partner {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 50vw !important;
    height: auto;
  }

  .client-content-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-left: 30px;
    /* padding-right: 10px; */
  }

  .name {
    /* margin: auto !important; */
  }
}

@media (min-width: 426px) {
  /* .home-partner-img {
    width: 50%;
  } */
}

@media (max-width: 425px) {
  .home-partner-img {
    width: 72%;
  }
}

/** */

/* #partners {
  min-height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 80px 0 0 0;
  opacity: 1;
}

.vertical-align-middle {
  vertical-align: middle;
  align-content: center;
  align-items: center;
}

#container_partners {
  max-width: none;
} */
#home-contact section.hero div.hero-body div.react-reveal {
  width: 100%;
}

#home-contact .hero-head h1 {
  font-weight: 600;
}

#home-contact .hero-head h1.subtitle.is-4 {
  margin-bottom: 12px;
}

#home-contact, section#contact {
  background: -webkit-linear-gradient(whitesmoke, lightgrey);
  background: linear-gradient(whitesmoke, lightgrey);
}

#home-contact section#contact {
  background: none;
}
@media (max-width: 1023px) {  

  #contact-email {
    font-size: 0.85em;
    letter-spacing: 1px;
  }

  .contact-details {
    margin: auto;
    width: 100%;
  }

  #contact-email-content {
    display: inline-block;
    margin: auto;
  }

  img.contact-icon {
    display: inline-block;
  }
}

@media (min-width: 1024px) {

  #contact-email {
    font-size: 0.95em;
    letter-spacing: 1px;
  }

}

/** Hide border-right line */
#contact-details {
  border: none;
}

.contact-subtitle {
  
    width: 185px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
   
}


/* section#home-contact  */

#contact h2 {
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: .5714em;
}
.contact-page-icon {
    height: 25px;
}

/** Bottom details */

/** Align mail icon with address */
#contact-detail-icon {
  display: inline-block;
}

@media (max-width: 425px) {
  div#map_wrapper {
    -webkit-align-items: center;
            align-items: center;
    display: block;
    margin-bottom: 10px;
    /* width: 100%; */
  }

    #contact-details {
      border: none;
    }

    div#contact_map, div#map_desc {
      display: block;
    }

    div#map_desc {
      padding: 0 20px;
    }

    div#contact_map {
      height: 200px;
      padding: 0 20px;
    }

    section#contact {
      padding-left: 25px;
    }
}

@media (min-width: 426px) {
  div#map_wrapper {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px;
    /* width: 100%; */
  }

    div#contact_map, div#map_desc {
        display: inline-block;
    }

    div#contact_map {
      height: auto;
      padding: 0;
    }

    div#map_desc {
        vertical-align: middle;
        padding: 0 20px;
    }

        /* div#map_desc > h3 {
            font-size: 20px;
            letter-spacing: 2px;
            font-weight: 600;
        } */

        div#map_desc > p, div#map_desc > p > span {
            font-size: 0.95em;
            letter-spacing: 1px;
        }

    /** Keep section no. row in line */
    section#contact > div.contact-content {
        padding-top: 0;
    }

  /* #contact-details h2, #social-details h2 {
    display: block;
  } */
}

@media (min-width: 768px) {
  section#contact {
    padding-left: 35px;
  }
}

@media (max-width: 425px) {
  p.locations {
    font-size: 15px
  }

  span.locations {
    font-size: 15px
  }
}
section#approach {
  background-image: -webkit-linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.45)
    ),
    url(/static/media/home-office_bright.3a941ea4.jpg);
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.45)
    ),
    url(/static/media/home-office_bright.3a941ea4.jpg);
  background-attachment: fixed;
  background-size:cover;
  
  width:100%;
 

  
  /* background: #0f0; */
}

div#approach-wrapper {
 
  padding-top:1%;
  padding-bottom:1%;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.9)
  );
  background: linear-gradient(
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.9)
  );
}

section#landing .react-reveal h1.subtitle {
  font-weight: normal;
}

section#landing .react-reveal h1.title {
  font-size: 2.5rem;
}


/** SERVICES OFFERED wrapper*/
#header_wrapper {
    background-image: -webkit-linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/ServicesIntro.da7ba2a0.jpg);
    background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/ServicesIntro.da7ba2a0.jpg);
	background-attachment: fixed;
}

div.panel-content {
    /* width: 100%; */
}

/** Translucent white background */
/* #header_alpha_bg {
    background-color: #fff;
    opacity: 0.7;
} */

#header_intro {
    padding: 70px 0;
    text-align: center;
    font-family: SourceSansPro;

    /** Space main content away from navbar */
    margin: 0 auto;
}

    #header_intro h1 {
        display: inline-block;
        margin: 0 0 0.5em 0;
    }

    #header_intro p {
        font-size: 18px;
    }

/** Wrapper for title and subtitle */
.title-wrapper {
    text-align: center;
    margin: 0 auto;
}

/** Titles formatting */
.panel-right h4, .panel-left h4, .title-big {
    font-family: "FiraSans";
    letter-spacing: 0.1em;
}

    /** Big title */
    h1.title-big {
        font-size: 3em;
        display: inline;
        /* height: 100%; */
    }

    /** Subtitle */
    h4.subtitle {
        display: inline;
        letter-spacing: 0.1em;
    }

/** Mobile S -- 320px *//* CURRENTLY FIXING */
@media (min-width: 320px) {
    #header_intro {
        max-width: 320px;
    }

    #header_intro p {
        margin: 0 20px;
    }

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 2.5em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 18px;
            font-weight: bold;
            /* display: flex; */
            /* justify-content: space-between */
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 1.05em;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
            line-height: 1.25;  /* 1.25*/
            max-width: 260px;   /*300px*/
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        /* border: 1px solid #aaa; */
        border-bottom: 2px solid #888;
        margin: 0;
    }

    .service-row {
        height: 540px;
        /* width: 425px; */
        width: 100%;
        margin: 0 auto;
        display: block;
        padding: 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            padding: 10px 10px;
            display: block;
            /* justify-content: space-between; */
            width: 320px;
            height: 180px;
            overflow: hidden;
        }

        .service-panel-last {
            border: none;
        }

        .service-panel {
            border-bottom: 1px solid #aaa;
        }

            .panel-content {
                display: inline-block;
                margin: 0 auto;
            }

            .panel-left {
                width: 30px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 270px;
                height: 150px;
                overflow: hidden;
            }
}

/** Mobile M -- 375px *//* CURRENTLY FIXING */
@media (min-width: 375px) {
    #header_intro {
        max-width: 320px;
    }

    /* p {
        font-size: 14px;
    } */

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 3em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 21px;
            font-weight: bold;
            /* display: flex; */
            /* justify-content: space-between */
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 21px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 1.25em;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
            line-height: 1.25;  /* 1.25*/
            max-width: 350px;   /*300px*/
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        /* border: 1px solid #aaa; */
        border-bottom: 2px solid #888;
        margin: 0;
    }

    .service-row {
        height: 540px;
        /* width: 425px; */
        width: 100%;
        margin: 0 auto;
        display: block;
        padding: 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            padding: 10px 0;
            display: block;
            /* justify-content: space-between; */
            width: 320px;
            height: 180px;
            overflow: hidden;
        }

        .service-panel-last {
            border: none;
        }

        .service-panel {
            border-bottom: 1px solid #aaa;
        }

            .panel-content {
                display: inline-block;
                margin: 0 auto;
            }

            .panel-left {
                width: 30px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 290px;
                height: 150px;
                overflow: hidden;
            }
}

/** Mobile L -- 425px */
@media (min-width: 425px) {
    #header_intro {
        max-width: 350px;
    }

    /* p {
        font-size: 14px;
    } */

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 3em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 21px;
            font-weight: bold;
            /* display: flex; */
            /* justify-content: space-between */
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 21px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 1.25em;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
            line-height: 1.25;  /* 1.25*/
            max-width: 350px;   /*300px*/
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        /* border: 1px solid #aaa; */
        border-bottom: 2px solid #888;
        margin: 0;
    }

    .service-row {
        height: 540px;
        /* width: 425px; */
        width: 100%;
        margin: 0 auto;
        display: block;
        padding: 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            padding: 15px;
            display: block;
            /* justify-content: space-between; */
            width: 400px;
            height: 180px;
        }

        .service-panel-last {
            border: none;
        }

        .service-panel {
            border-bottom: 1px solid #aaa;
        }

            .panel-content {
                display: inline-block;
                margin: 0 auto;
            }

            .panel-left {
                width: 40px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 330px;
                height: 140px;
                overflow: hidden;
            }
}

/** Tablet -- 768px   */
@media (min-width: 768px) {
    
    #header_intro {
        max-width: 700px;
    }

    /* p {
        font-size: 14px;
    } */

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 3em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 18px;
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 18px;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 15px;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
        }

            /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        border: 1px solid #aaa;
        margin: 0 0 5px 0;
    }

    .service-row {
        height: 240px;
        width: 750px;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        padding: 20px 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            /* display: flex; */
            /* justify-content: space-between; */
            padding: 8px;
            /* display: initial; */
            width: 200px;
            height: 200px;
            border: none;
        }

            .panel-content {
                display: inline-block;
                margin: 0px;
                overflow: hidden;
            }

            .panel-left {
                width: 40px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 200px;
                height: 130px;
            }
}

/** iPad Pro -- 1024px */
@media (min-width: 1024px) {

    p.service-desc {
        font-size: 0.9em;
        margin: 0;
    }

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 3em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 20px;
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 20px;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 16px;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        border: 1px solid #aaa;
        margin: 0 0 5px 0;
    }

    .service-row {
        height: 240px;
        width: 900px;
        /* display: flex; */
        /* justify-content: space-between; */
        margin: 0 auto;
        padding: 20px 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            width: 270px;
            height: 200px;
            border: none;
        }

            .panel-content {
                display: inline-block;
                margin: 0px;
            }

            .panel-left {
                width: 50px;
                height: 100px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 190px;
                height: 180px;
                overflow: hidden;
            }

            .panel-right h4 {
                height: 2em;
                margin-bottom: 20px;
                /* letter-spacing: 0; */
                line-height: 1em;
            }
}

@media (min-width: 1440px) {

        p.service-desc {
            font-size: 0.9em;
            margin: 0;
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        border: 1px solid #aaa;
        margin: 0 0 5px 0;
    }

    .service-row {
        height: 240px;
        width: 1300px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        margin: 0 auto;
        padding: 20px 0;
    }

        .service-panel, .service-panel-last {
            width: 400px;
            height: 250px;
        }

            .panel-content {
                display: inline-block;
                margin: 0px;
            }

            .panel-left {
                width: 50px;
                height: 100px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 300px;
                height: 200px;
                overflow: hidden;
            }
}


.service-row {
    /* height: 240px; */
    /* display: flex; */
    /* justify-content: space-between; */
    /* margin: 0 auto; */
    /* padding: 20px 0; */
}

    .service-panel {
        /* width: 400px;
        height: 250px; */
    }
    
        .panel-content {
            /* display: inline-block;
            margin: 0px; */
        }

        /* Panel */
        .panel-left {
            /* width: 50px;
            height: 100px;
            line-height: 1;
            vertical-align: top; */
        }

        .panel-right {
            /* width: 300px;
            height: 200px;
            overflow: hidden; */
        }
    
div div #services { /** Override inline style*/
    width: 100%;
    /* min-height: 100vh; */
    padding: 50px 0 70px 0; /* Header intro padding - service row margin-bottom */
}

#container_tech {
    min-height: 0;
}

/* Overrides 1200px max-width*/
@media (min-width: 1400px) {#services .container {
    /* max-width: 1300px; */
}}

/* .service-desc {
    margin-top: 2rem;
}

.service-item {
    margin-top: 1.3rem;
} */

.service-number {
    font-family: FiraSans-Medium, SourceSansPro-Semibold, sans-serif;
    font-size: 20px;
    text-indent: 5px;
    letter-spacing: 5px;
}

.service-name {
    font-family: FiraSans, SourceSansPro, sans-serif;
    font-size: 20px;
    text-indent: 5px;
    letter-spacing: 5px;
    border-bottom: 1px solid #000;
}

a {
    color: black;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color .4s linear;
    transition: color .4s linear;
}

a:hover {
    text-decoration: none;
    color: rgba(0, 1, 5, 0.568);
}

@media (min-width: 1200px) {.placeholder {
    background-color: #555;
    width: 176px;
    height: 99px;
}}

@media (min-width: 1400px) {.placeholder {
    background-color: #555;
    width: 224px;
    height: 126px;
}}

div.tech-row > div.tile {   
    background-color: #f7f7f7;
    border-radius: 5px;
}

div.tile > img {
    display: block;
    margin: auto;
    vertical-align: middle;
}

#works-div-render {
    background: #F5F5F5;
}

.pointer {cursor: pointer;}

@media (max-width: 1024px) {
    /* nav bar links */
    nav#category-bar {
        display: none;
    }

    /* containers */
    div.container {
        padding-left: 35px;
        padding-right: 35px;
    }

    /* resize images */
    img.icon {
        width: 200px;
    }
}

/* mobile */
@media (max-width: 425px) {
    div.our-works-wrapper {
        
    }
}
.workImg {
    cursor: pointer;
    border: 0.5rem solid transparent;
    justify-self: center;
}

.workImg:hover {
    border: 0.3rem solid transparent;
}
p{
    font-size: 20px;
    margin: 0 0 0 10px;
}
#workModal {
    width: 100%;
    margin-bottom: 100px;
    position: absolute;
    z-index: 9999;
    background-color: #F5F5F5
}

.work-title-header {
    font-family: FiraSans-Medium, SourceSansPro-Semibold;
    font-size: 24px;
    letter-spacing: 3px; 
    padding: 1.5rem;
  }
  
#figure-img {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 75vh;
    background-color: #fff;
    -webkit-justify-content: center;
            justify-content: center;
}
  
#figure-img > img {
    min-height: 100%;
    object-fit: contain;
}
  
.work-title {
    font-family: FiraSans-Medium, SourceSansPro-Semibold;
    font-size: 24px;
    letter-spacing: 3px; 
    margin-top: 1rem;
}

.work-subtitle {
    font-family: FiraSans, SourceSansPro;
    font-size: 20px;
    letter-spacing: 3px; 
}

.work-details {
    font-family: FiraSans, SourceSansPro;
    font-size: 16px;
    margin-top: 2.5rem;
    margin-bottom: 5rem;
}
#imagegallery1{
    width: 100%;
}
@media (max-width:600px)
{
    #imagegallery1{
        height:200px;
    }
}
/** Override default rules */
nav ul li a {
    color: #000 !important;
    border: none;
    text-align: center;
    text-decoration: none;
}

div div #works {
    width: 100%;
    /* min-height: 55vh; */
    /* padding-top: 70 + 72 + 20px; */
    padding-top: 20px;
}

.service-desc {
    margin-top: 2rem;
}

.service-item {
    margin-top: 1.3rem;
}

.service-number {
    font-family: FiraSans-Medium, SourceSansPro-Semibold, sans-serif;
    font-size: 20px;
    text-indent: 5px;
    letter-spacing: 5px;
}

.service-name {
    font-family: FiraSans, SourceSansPro, sans-serif;
    font-size: 20px;
    text-indent: 5px;
    letter-spacing: 5px;
    border-bottom: 1px solid #000;
}

a {
    color: black;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color .4s linear;
    transition: color .4s linear;
}

a:hover {
    text-decoration: none;
    color: rgba(0, 1, 5, 0.568);
}

.placeholder {
    
}

@media (min-width: 320px) {

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 12px;
        padding-right: 0;
        width: 80%;
    }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    .placeholder {
        width: 256px;
        height: 144px;
        margin: auto;
    }

    /** Override default setting*/
    /** Contents of each work panel */
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 10px 0px 10px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 20px;
        text-transform: uppercase;
        margin: 10px 0 5px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2.5em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 17px;
            line-height: 1.25;
            max-width: 280px;
            margin: auto;
        }

    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: -webkit-linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 30px 0;
        }

            div#wk_header > h1 {
                font-size: 2.4em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 1.05em;
                margin: 0 auto;
                width: 270px;
            }

    /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            display: -webkit-inline-flex;
            display: inline-flex;
            height: inherit;
            line-height: 48px;
            vertical-align: middle;
            text-align: center;
            width: inherit;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 0.8em;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 16px;
                padding: 0;
                height: inherit;
                text-transform: uppercase;
                text-indent: 0px;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                width: 33.333%;

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
            }

                nav#category-bar ul li a {
                    display: -webkit-inline-flex;
                    display: inline-flex;
                    -webkit-justify-content: center;
                            justify-content: center;
                    -webkit-flex-direction: column;
                            flex-direction: column;
                    height: inherit;
                    width: 100%;
                    /* height: inherit; */
                    margin: 0 1px;
                    padding: 0 auto;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }
}

@media (min-width: 375px) {

    /** Section no. */
    .row .section_no {
        /* margin-right: 5px; */
        width: 10%;
    }

        .row .section_no span {
            font-size: 1.5em;
        }

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 10px;
        padding-right: 0;
        width: 80%;
    }

        .row .work_section_title h2 {
            width: 100%;
        }

            .row .work_section_title h2 span {
                font-size: 0.5em;
                letter-spacing: 2px;
            }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    .placeholder {
        width: 256px;
        height: 144px;
        margin: auto;
    }

    /** Override default setting*/
    /** Contents of each work panel */
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 24px 0px 24px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 20px;
        text-transform: uppercase;
        margin: 10px 0 5px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2.5em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 17px;
            line-height: 1.25;
            max-width: 300px;
            margin: auto;
        }

    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: -webkit-linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 36px 0;
        }

            div#wk_header > h1 {
                font-size: 2.5em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 1.05em;
                margin: 0 auto;
                width: 310px;
            }

    /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            display: -webkit-inline-flex;
            display: inline-flex;
            -webkit-justify-content: space-between;
                    justify-content: space-between;
            height: inherit;
            line-height: 48px;
            vertical-align: middle;
            text-align: center;
            width: inherit;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 0.9em;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;
                text-transform: uppercase;
                text-indent: 0px;

                /** The rest */
                display: inline-block;
                list-style: none;
                padding: 0;
                text-align: center;
                vertical-align: middle;
                
                width: 33.333%;
            }

                nav#category-bar ul li a {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: center;
                            justify-content: center;
                    -webkit-flex-direction: column;
                            flex-direction: column;
                    height: inherit;
                    width: 100%;
                    /* height: inherit; */
                    padding: 0 auto;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }

                        /** Center span inside anchor */
                        nav#category-bar ul li a span  {
                            margin: auto;
                        }
}

@media (min-width: 425px) {

    /** Section no. */
    .row .section_no {
        /* margin-right: 5px; */
        width: 5%;
    }

        .row .section_no span {
            font-size: 1.5em;
        }

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 10px;
        padding-right: 0;
        width: 85%;
    }

    .row .work_section_title h2 {
        padding-right: 0;
        width: 100%;
    }

    .placeholder {
        width: 288px;
        height: 162px;
        margin: auto;
    }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    /** Override default setting*/
    /** Contents of each work panel */
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 24px 0px 24px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 1.4em;
        text-transform: uppercase;
        margin: 10px 0 0px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2.5em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 1em;
            line-height: 1.25;
            max-width: 300px;
            margin: auto;
        }

    /** Banner */
    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: -webkit-linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-attachment: fixed;
        background-position-x: 70%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 50px 0;
        }

            div#wk_header > h1 {
                font-size: 2.7em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 1.05em;
                margin: 0 auto;
                width: 370px;
            }

        /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            height: inherit;
            line-height: 48px;
            width: inherit;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
                text-indent: 0px;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                padding: 0;
                /* margin: 0 20px; */

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
                width: 33.3333%;
            }

                nav#category-bar ul li a {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: center;
                            justify-content: center;
                    -webkit-flex-direction: column;
                            flex-direction: column;
                    height: inherit;
                    width: 100%;
                    /* height: inherit; */
                    padding: 0 12px;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }
}


@media (min-width: 768px) {

    /** Section no. */
    .row .section_no {
        /* margin-right: 5px; */
        width: 10%;
    }

        .row .section_no span {
            font-size: 1.5em;
        }

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 0;
        padding-right: 0;
        width: 90%;
    }

    .row .work_section_title h2 {
        padding-right: 0;
        width: 100%;
    }

    .placeholder {
        width: 240px;
        height: 135px;
        margin: auto;
    }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    /** Override default setting*/
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 50px 0px 50px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 20px;
        text-transform: uppercase;
        margin: 10px 0 5px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2.5em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 14px;
            line-height: 1.25;
            max-width: 300px;
            margin: auto;
        }

    /** Banner */
    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: -webkit-linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 70px 0;
        }

            div#wk_header > h1 {
                font-size: 3em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 18px;
                margin: 0 auto;
                width: 700px;
            }

        /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            height: inherit;
            line-height: 48px;
            width: inherit;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
                text-indent: 0px;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                padding: 0;
                /* margin: 0 20px; */

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
                width: auto;
                width: initial;
            }

                nav#category-bar ul li a {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: center;
                            justify-content: center;
                    -webkit-flex-direction: column;
                            flex-direction: column;
                    height: inherit;
                    width: inherit;
                    /* height: inherit; */
                    padding: 0 20px;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }

                        nav#category-bar ul li a span:hover {
                            /* background-color: #000; */
                        }

                nav#category-bar ul li.two-line {
                    
                }

                nav#category-bar ul li.one-line {
                    line-height: 32px;
                }

                /* li #nav_solutions a span {
                    display: inline-block;
                    text-align: center;
                    margin: auto 0;
                } */

    img.logo {
        width: 250px;
    }

    div.desc {
        display: none;
    }
}

/** =1024px */
@media (min-width: 1024px) {

    /** Section no. */
    .row .section_no {
        /* margin-right: 5px; */
        width: 10%;
    }

        .row .section_no span {
            font-size: 1.5em;
        }

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 0;
        padding-right: 0;
        width: 90%;
    }

    .row .work_section_title h2 {
        padding-right: 0;
        width: 100%;
    }

    .placeholder {
        width: 240px;
        height: 135px;
        margin: auto;
    }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    /** Override default setting*/
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 50px 0px 50px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 18px;
        text-transform: uppercase;
        margin: 10px 0 5px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2em;
        line-height: 1em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 14px;
            line-height: 1.25;
            max-width: 300px;
            margin: 5px auto 0 auto;
        }

    /** Banner */
    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: -webkit-linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 70px 0;
        }

            div#wk_header > h1 {
                font-size: 3em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 18px;
                margin: 0 auto;
                width: 700px;
            }

        /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            height: inherit;
            line-height: 48px;
            width: inherit;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
                text-indent: 0px;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                padding: 0;
                /* margin: 0 20px; */

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
                width: auto;
                width: initial;
            }

                nav#category-bar ul li a {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: center;
                            justify-content: center;
                    -webkit-flex-direction: column;
                            flex-direction: column;
                    height: inherit;
                    width: inherit;
                    /* height: inherit; */
                    padding: 0 20px;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }

                        nav#category-bar ul li a span:hover {
                            /* background-color: #000; */
                        }

                nav#category-bar ul li.two-line {
                    
                }

                nav#category-bar ul li.one-line {
                    line-height: 32px;
                }

                /* li #nav_solutions a span {
                    display: inline-block;
                    text-align: center;
                    margin: auto 0;
                } */
}

/** =1440px */
@media (min-width: 1440px) {

    /** Section name */
    .row-title .row-right {
        border-left: 3px solid black;
    }

        .row-title .row-right h2 {
            /* width: 80%; */
        }
    
    .placeholder {
        width: 224px;
        height: 126px;
    }

    .text-wrapper {
        text-align: left;
        text-align: initial;
    }

    /** Override default setting*/
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 40px 50px 0px 50px;
        
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 20px;
        text-transform: uppercase;
        margin: 0 0 0 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2em;
        line-height: 1em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 14px;
            line-height: 1.25;
            max-width: 300px;
            margin: 7px 0 0 10px;
        }

    /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
        /* margin-top: 150px; */
    }

        #category-bar ul {
            height: inherit;
            line-height: 48px;
            width: inherit;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
                text-indent: 0px;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                padding: 0;
                /* margin: 0 20px; */
                width: auto;
                width: initial;

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
            }

                nav#category-bar ul li a {
                    display: -webkit-flex;
                    display: flex;
                    -webkit-justify-content: center;
                            justify-content: center;
                    -webkit-flex-direction: column;
                            flex-direction: column;
                    height: inherit;
                    width: inherit;
                    /* height: inherit; */
                    padding: 0 20px;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }

    /** Banner */
    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: -webkit-linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url(/static/media/laptop_discussion.30e7a5c4.jpg);
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }
   

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 70px 0;
        }

            div#wk_header > h1 {
                font-size: 3em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 18px;
                margin: 0 auto;
                width: 700px;
            }
    /* Allow the elements to be in line with each other */
    .lineup {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        text-align: initial;
    }

    p.work-desc {
        text-align: left;
    }
}

/* General */

/** Our Works */
.our-works-wrapper {
    margin-top: 20px;
}

/** Titles */
.section-title, .section-subtitle {
    display: inline-block;
}

div.title-subtitle-wrapper {
    margin-left: 14px;
}

.section-number {
    text-align: right;
}

a.anchor {
    display: block;
    position: relative;
    top: -200px;
    visibility: hidden;
}

/* Overrides .content-title -> for re-adjusting indent */

/* .service-title {
    font-family: FiraSans, SourceSansPro;
    font-size: 20px;
    text-transform: uppercase;
    margin: 0 0 0 10px;
    text-indent: 3px;
    letter-spacing: 3px;
} */

/* .work-desc {
    font-family: Fira Sans, SourceSansPro;
    font-size: 14px;
    line-height: 1.25;
    max-width: 300px;
} */

.is-current {
    background-color: #cccccc;
}

div#works-wrapper {
    overflow: visible;
    margin-top: 70px;
}
div#works-wrapper1 {
    overflow: visible;
    margin-top: 0px;
}
#paragraph1{
    text-align:center;
    font-family: Fira Sans, SourceSansPro;
    font-size: 3em;
    line-height: 1.25;

}
.image-gallery{
    width:100%;
    margin:0 auto;
}
.image-gallery-slide img {
    width: 100%;
    height: 55vh;
}
.image-gallery-description {
    font-size:180%;
    position: absolute;
    height:6%;
    top:0px;
    width: 100%;
    text-align:center;
    padding: 10px 0px 10px 0px;
}
.image-gallery-button {
    font-size:180%;
    position: absolute;
    height:6%;
    bottom:0px;
    width: 100%;
    text-align:center;
    padding: 10px 0px 10px 0px;
}
.usecaseheader{
    text-align:center;
    font-size:2em;
    margin:70px;
    /*font-family: "Times New Roman", Times, serif;*/
    
    padding:auto;
    color:#8B0000;
}
.usecaseheader1{
    text-align:center;
    font-size:2em;
    margin:70px;
    
    padding:auto;
    color:#0a539d;;
}
.carouselfeature{
    margin-right:1%;
}






/* .image-gallery-right-nav::before{
    background-color: red;
}
.image-gallery-left-nav::before{
    background-color: red;
} */
/* #main-team {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
} */

.owl-theme {
    padding: 20px;

}
/* 
#landing2 {
    min-height: 100%;
    min-height: 100vh;
    width: 100%;
    padding: 80px 0 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    align-content: center;
} */

.card {
    width: 13rem;
    border: none;
    padding: 5px;
    margin-right: 25px;
    background-color: transparent;
    cursor: pointer;
}

.member-name {
    font-family: FiraSans-Medium, SourceSansPro-Semibold;
    font-size: 1rem;
    text-transform: uppercase;
    text-indent: 2px;
    letter-spacing: 2px;
}

.member-position {
    font-family: FiraSans, SourceSansPro;
    font-size: 14px;
    text-indent: 2px;
    letter-spacing: 2px;
}

#sdp-team {
    /* width: 100%; */
    height: 80vh;
    -webkit-align-content: center;
            align-content: center;
    vertical-align: middle;
    margin: auto;
    padding: 0;
}

.closeBtn {
    background-color: #F5F5F5;
    border: 0;
    border-bottom: 1px solid #000;
    cursor: pointer;
    font-family: FiraSans-Medium, SourceSansPro-Semibold;
    font-size: 14px;
    text-indent: 3px;
    letter-spacing: 3px;
}

.break {
    display: none;
}

/* TABLET */
@media (max-width: 768px) and (min-width: 426px) {
    /* about ezsofe */
    div.about-ezsofe {
        padding-left: 35px;
    }

    /* optimizing ur capablities */
    div.optimizing {
        padding-left: 35px;
        padding-right: 35px;
    }

    /* the team */
    div.the-team {
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap; */
        padding-left: 35px;
        padding-right: 35px;
    }

    .tile-team {
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap; */
        /* width: 100%; */
        /* padding-left: 30px;
        padding-right: 30px; */
    }

    .staff {
        /* display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 30px;
        padding-right: 30px; */
    }

    .about-team-top {
        /* width: 50%; */
    }

    /* STUDENT DEVS */
    div.row-student-devs {
        padding-left: 35px;
        padding-right: 35px;
    }

    /* .students_columns {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .students {
        width: 100px;
    }
    
    .student-devs {
        width: 100px !important;
    } */
}

@media (max-width: 768px) {
    h5#student_devs_desc {
        font-size: 0rem;
    }
}

@media (max-width: 425px) {
    /* optimizing your capabilities */
    .content-title-light {
        padding-left: 10px;
        width: 100vw
    }

    .client-content-btn {
        padding-left: 30px;
    }
}

@media (max-width: 320px) {
    .break {
        display: inline;
        display: initial;
    }
}

/** */
div.about-space {
    height: 52px;
}

div.about-title {
    height: 50px;
}

div.title-border {
    border-bottom: 1px solid black;
}

/** After adjusting Bulma */

div#about-top-spacing {
    height: 20px;
}

div.about-small-spacing {
    height: 50px;
}


* {
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
}

#app .hero-body {
  padding-top: 30px;
  padding-left:0px;
  padding-right:0px;
  -webkit-justify-content: center;
          justify-content: center;
}

/**************************************************************/

/************************* T E X T S **************************/

/**************************************************************/

.title {
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
  font-size: 72px;
  text-transform: uppercase;
  text-indent: 25px;
  letter-spacing: 25px;
}

@media only screen and (max-width: 426px) {
  .title {
    font-size: 36px;
  }
}

.subtitle {
  font-family: FiraSans, SourceSansPro-Semibold;
  font-size: 24px;
  text-transform: uppercase;
  text-indent: 25px;
  letter-spacing: 10px;
}

.subtitle-light {
  font-family: FiraSans, SourceSansPro;
  font-size: 16px;
  text-transform: uppercase;
  text-indent: 5px;
  letter-spacing: 5px;
}

.section-h2 {
  line-height: 0.7;
  padding: 10px 10px 10px 0;
}

.section-title {
  font-family: FiraSans-Semibold, SourceSansPro-Semibold;
  font-size: 20px;
  text-transform: uppercase;
  text-indent: 5px;
  letter-spacing: 5px;
}

.section-subtitle {
  font-family: FiraSans, SourceSansPro;
  font-size: 16px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
}

.section-number {
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
  font-size: 48px;
  text-transform: uppercase;
  letter-spacing: 15px;
}

.content-quote {
  font-family: FiraSans-Medium;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.content-title-light {
  font-family: FiraSans-Light, SourceSansPro-Light;
  font-size: 20px;
  text-transform: uppercase;
  text-indent: 5px;
  letter-spacing: 5px;
}

.content-title {
  font-family: FiraSans, SourceSansPro;
  font-size: 20px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
}

.content-para {
  font-family: Fira Sans, SourceSansPro;
  font-size: 14px;
  line-height: 2;
}

.content-para-light {
  font-family: FiraSans-Light, SourceSansPro-Light;
  font-size: 14px;
  text-transform: uppercase;
  text-indent: 5px;
  letter-spacing: 5px;
}

.content-para-16px {
  font-family: Fira Sans, SourceSansPro;
  font-size: 16px;
  line-height: 2;
}

.content-para-20px {
  font-family: Fira Sans, SourceSansPro;
  font-size: 20px;
  line-height: 2;
}

.button-link {
  color: #000;
  font-family: FiraSans, SourceSansPro;
  font-size: 18px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
  background-color: #f5f5f5;
  border: 1px solid #000;
  padding: 10px;
}

.button-link:hover {
  color: #000;
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
  text-decoration: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(37, 37, 37, 0.6);
  text-decoration: none;
}

.page-title-medium {
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
  font-size: 24px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
}

.page-title-reg {
  font-family: FiraSans, SourceSansPro;
  font-size: 24px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
}

a,
a:hover {
  color: #000;
  text-decoration: none;
}

div#app-container {
  margin: 0;
  padding: 0;
  width: 100%;
}

.para1{


        position: relative;
        top: 10em;
        text-align:center;
        z-index: 1;
      
}
.containerusecase:hover{
    opacity:0.2;
    
}
.para2{
    margin-bottom:10%;
    margin-top:4%;
    font-size:16px;
}
.featureHeader{
    position: relative;
        top: 10em;
        text-align:center;
        z-index: 1;
        margin:0;
}
@media(max-width:800px){
    .image-gallery-slide img{
        width:70vw;
    }
}

@media (max-width: 1024px){
    /* descriptions */
    .content-para {
        font-size: 13px !important
    }

    /* iphone picture */
    #iphonemac {
        width: 55% !important
    }
}

@media (max-width: 768px) {
    /* bus and handshake pics */
    #picture {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    
    /* heading */
    .content-title-light {
        padding-left: 25px !important;
    }

    /* description */
    .content-para {
        padding-left: 30px !important;
        padding-right: 30px !important;
        font-size: 15px !important
    }

    #iphonemac {
        /* display: flex; */
        display: block;
        margin-left: auto;
        margin-right: auto;
        /* width: 10%; */
        /* padding-left: 80px */
    }

    /* #iphone {
        width: 80%;
        height: auto;
    }

    #mac {
        width: 80%;
        height: auto;
    }  */

    /* #iphonepic {
        height: auto;
        width: auto
    }

    #macpic {
        height: auto;
        width: auto
    } */

    /* heading */
    .ccol-md-11 {
        text-align: center
    }
    .content-title-light {
        text-align: center
    }

    /* features description */
    p#desc {
        padding-left: 30px
    }
    p.content-para {
        padding-left: 30px
    }
}

@media (max-width: 425px) {
    #iphonemac {
        display: block;
        margin-left: auto;
        margin-right: auto;
        /* width: 50%; */
        padding-left: 30px;
        /* padding-right: 2px; */
    }

    /* #iphone {
        padding-right: 30px;
    }

    #mac {
        padding-right: 30px;
    } */

}

@media (max-width: 375px) {
    #iphone {
        padding-right: 30px;
    }

    #mac {
        padding-right: 30px;
    }
}
