#clients {
    min-height: 100%;
    min-height: 100vh;
    width: 100%;
    padding: 80px 0 0 0;
}

.client-content-item {
    width: 100%;
    border: 0px;
    height: auto;
    overflow: hidden;
    margin-top: 25px;
    margin-bottom: 25px;
    z-index: 1;
}

.client-content-btn {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/***/

#home-works-content {
    margin: auto;
}

#works .hero-body .react-reveal {
    margin: auto;
    vertical-align: middle;
}

#home-works-content .column {
  padding: 20px;  
}


#home-works-content .column img {
  /* display: block; */
  /* margin: auto; */
  max-height: 80px;
}

@media (max-width: 768px) and (min-width: 426px) {
  #column {
    display: flex;
    flex-direction: row;
    width: 100vw;
    /* padding-right: 10px; */
  }

  #home-works-content {
    /* padding-right: 10px; */
  }

  .individual {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* width: 90vw; */
    height: auto;
  }
  
  .content-title {
    width: 20vw;
    padding-left: 0px !important;
  }

  .image {
    width: 20vw;
  }

  .client-content-btn {
    padding-left: 30px;
    width: 100vw;
  }

  .content-title-light {
    padding-right: 10px;
  }
}

@media (max-width: 425px) {
  .content-title-light {
    padding-right: 10px;
  }

  .client-content-btn {
    padding-right: 30px;
  }
}