.card {
    width: 13rem;
    border: none;
    padding: 5px;
    margin-right: 25px;
    background-color: transparent;
    cursor: pointer;
}

.avatar {
    max-height: 200px;
    border-radius: 50%;
    border:0px;
}