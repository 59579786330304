/** Override default rules */
nav ul li a {
    color: #000 !important;
    border: none;
    text-align: center;
    text-decoration: none;
}

div div #works {
    width: 100%;
    /* min-height: 55vh; */
    /* padding-top: 70 + 72 + 20px; */
    padding-top: 20px;
}

.service-desc {
    margin-top: 2rem;
}

.service-item {
    margin-top: 1.3rem;
}

.service-number {
    font-family: FiraSans-Medium, SourceSansPro-Semibold, sans-serif;
    font-size: 20px;
    text-indent: 5px;
    letter-spacing: 5px;
}

.service-name {
    font-family: FiraSans, SourceSansPro, sans-serif;
    font-size: 20px;
    text-indent: 5px;
    letter-spacing: 5px;
    border-bottom: 1px solid #000;
}

a {
    color: black;
    text-decoration: none;
    cursor: pointer;
    transition: color .4s linear;
}

a:hover {
    text-decoration: none;
    color: rgba(0, 1, 5, 0.568);
}

.placeholder {
    
}

@media (min-width: 320px) {

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 12px;
        padding-right: 0;
        width: 80%;
    }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    .placeholder {
        width: 256px;
        height: 144px;
        margin: auto;
    }

    /** Override default setting*/
    /** Contents of each work panel */
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 10px 0px 10px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 20px;
        text-transform: uppercase;
        margin: 10px 0 5px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2.5em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 17px;
            line-height: 1.25;
            max-width: 280px;
            margin: auto;
        }

    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url('../../assets/images/laptop_discussion.jpg');
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 30px 0;
        }

            div#wk_header > h1 {
                font-size: 2.4em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 1.05em;
                margin: 0 auto;
                width: 270px;
            }

    /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            display: inline-flex;
            height: inherit;
            line-height: 48px;
            vertical-align: middle;
            text-align: center;
            width: inherit;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 0.8em;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 16px;
                padding: 0;
                height: inherit;
                text-transform: uppercase;
                text-indent: 0px;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                width: 33.333%;

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
            }

                nav#category-bar ul li a {
                    display: inline-flex;
                    justify-content: center;
                    flex-direction: column;
                    height: inherit;
                    width: 100%;
                    /* height: inherit; */
                    margin: 0 1px;
                    padding: 0 auto;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }
}

@media (min-width: 375px) {

    /** Section no. */
    .row .section_no {
        /* margin-right: 5px; */
        width: 10%;
    }

        .row .section_no span {
            font-size: 1.5em;
        }

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 10px;
        padding-right: 0;
        width: 80%;
    }

        .row .work_section_title h2 {
            width: 100%;
        }

            .row .work_section_title h2 span {
                font-size: 0.5em;
                letter-spacing: 2px;
            }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    .placeholder {
        width: 256px;
        height: 144px;
        margin: auto;
    }

    /** Override default setting*/
    /** Contents of each work panel */
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 24px 0px 24px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 20px;
        text-transform: uppercase;
        margin: 10px 0 5px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2.5em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 17px;
            line-height: 1.25;
            max-width: 300px;
            margin: auto;
        }

    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url('../../assets/images/laptop_discussion.jpg');
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 36px 0;
        }

            div#wk_header > h1 {
                font-size: 2.5em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 1.05em;
                margin: 0 auto;
                width: 310px;
            }

    /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            display: inline-flex;
            justify-content: space-between;
            height: inherit;
            line-height: 48px;
            vertical-align: middle;
            text-align: center;
            width: inherit;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 0.9em;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;
                text-transform: uppercase;
                text-indent: 0px;

                /** The rest */
                display: inline-block;
                list-style: none;
                padding: 0;
                text-align: center;
                vertical-align: middle;
                
                width: 33.333%;
            }

                nav#category-bar ul li a {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    height: inherit;
                    width: 100%;
                    /* height: inherit; */
                    padding: 0 auto;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }

                        /** Center span inside anchor */
                        nav#category-bar ul li a span  {
                            margin: auto;
                        }
}

@media (min-width: 425px) {

    /** Section no. */
    .row .section_no {
        /* margin-right: 5px; */
        width: 5%;
    }

        .row .section_no span {
            font-size: 1.5em;
        }

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 10px;
        padding-right: 0;
        width: 85%;
    }

    .row .work_section_title h2 {
        padding-right: 0;
        width: 100%;
    }

    .placeholder {
        width: 288px;
        height: 162px;
        margin: auto;
    }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    /** Override default setting*/
    /** Contents of each work panel */
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 24px 0px 24px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 1.4em;
        text-transform: uppercase;
        margin: 10px 0 0px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2.5em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 1em;
            line-height: 1.25;
            max-width: 300px;
            margin: auto;
        }

    /** Banner */
    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url('../../assets/images/laptop_discussion.jpg');
        background-attachment: fixed;
        background-position-x: 70%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 50px 0;
        }

            div#wk_header > h1 {
                font-size: 2.7em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 1.05em;
                margin: 0 auto;
                width: 370px;
            }

        /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            height: inherit;
            line-height: 48px;
            width: inherit;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
                text-indent: 0px;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                padding: 0;
                /* margin: 0 20px; */

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
                width: 33.3333%;
            }

                nav#category-bar ul li a {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    height: inherit;
                    width: 100%;
                    /* height: inherit; */
                    padding: 0 12px;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }
}


@media (min-width: 768px) {

    /** Section no. */
    .row .section_no {
        /* margin-right: 5px; */
        width: 10%;
    }

        .row .section_no span {
            font-size: 1.5em;
        }

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 0;
        padding-right: 0;
        width: 90%;
    }

    .row .work_section_title h2 {
        padding-right: 0;
        width: 100%;
    }

    .placeholder {
        width: 240px;
        height: 135px;
        margin: auto;
    }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    /** Override default setting*/
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 50px 0px 50px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 20px;
        text-transform: uppercase;
        margin: 10px 0 5px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2.5em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 14px;
            line-height: 1.25;
            max-width: 300px;
            margin: auto;
        }

    /** Banner */
    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url('../../assets/images/laptop_discussion.jpg');
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 70px 0;
        }

            div#wk_header > h1 {
                font-size: 3em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 18px;
                margin: 0 auto;
                width: 700px;
            }

        /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            height: inherit;
            line-height: 48px;
            width: inherit;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
                text-indent: 0px;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                padding: 0;
                /* margin: 0 20px; */

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
                width: initial;
            }

                nav#category-bar ul li a {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    height: inherit;
                    width: inherit;
                    /* height: inherit; */
                    padding: 0 20px;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }

                        nav#category-bar ul li a span:hover {
                            /* background-color: #000; */
                        }

                nav#category-bar ul li.two-line {
                    
                }

                nav#category-bar ul li.one-line {
                    line-height: 32px;
                }

                /* li #nav_solutions a span {
                    display: inline-block;
                    text-align: center;
                    margin: auto 0;
                } */

    img.logo {
        width: 250px;
    }

    div.desc {
        display: none;
    }
}

/** =1024px */
@media (min-width: 1024px) {

    /** Section no. */
    .row .section_no {
        /* margin-right: 5px; */
        width: 10%;
    }

        .row .section_no span {
            font-size: 1.5em;
        }

    /** Section name */
    .row .work_section_title {
        border-left: 3px solid black;
        margin-left: 0;
        padding-right: 0;
        width: 90%;
    }

    .row .work_section_title h2 {
        padding-right: 0;
        width: 100%;
    }

    .placeholder {
        width: 240px;
        height: 135px;
        margin: auto;
    }

    /* Allow the elements to be in line with each other */
    .lineup {
        display: block;
        text-align: center;
        /* vertical-align: 0; */
    }

    p.work-desc {
        text-align: left;
    }

    /** Override default setting*/
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 80px 50px 0px 50px;
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 18px;
        text-transform: uppercase;
        margin: 10px 0 5px 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2em;
        line-height: 1em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 14px;
            line-height: 1.25;
            max-width: 300px;
            margin: 5px auto 0 auto;
        }

    /** Banner */
    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url('../../assets/images/laptop_discussion.jpg');
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 70px 0;
        }

            div#wk_header > h1 {
                font-size: 3em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 18px;
                margin: 0 auto;
                width: 700px;
            }

        /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
    }

        #category-bar ul {
            height: inherit;
            line-height: 48px;
            width: inherit;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
                text-indent: 0px;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                padding: 0;
                /* margin: 0 20px; */

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
                width: initial;
            }

                nav#category-bar ul li a {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    height: inherit;
                    width: inherit;
                    /* height: inherit; */
                    padding: 0 20px;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }

                        nav#category-bar ul li a span:hover {
                            /* background-color: #000; */
                        }

                nav#category-bar ul li.two-line {
                    
                }

                nav#category-bar ul li.one-line {
                    line-height: 32px;
                }

                /* li #nav_solutions a span {
                    display: inline-block;
                    text-align: center;
                    margin: auto 0;
                } */
}

/** =1440px */
@media (min-width: 1440px) {

    /** Section name */
    .row-title .row-right {
        border-left: 3px solid black;
    }

        .row-title .row-right h2 {
            /* width: 80%; */
        }
    
    .placeholder {
        width: 224px;
        height: 126px;
    }

    .text-wrapper {
        text-align: initial;
    }

    /** Override default setting*/
    section#works div.service-content-item {
        /* background-color: #0f0; */
        padding: 40px 50px 0px 50px;
        
    }

    /* Overrides .content-title -> for re-adjusting indent */
    .work-title {   
        font-family: FiraSans, SourceSansPro;
        font-size: 20px;
        text-transform: uppercase;
        margin: 0 0 0 10px;
        /*text-indent: 3px;*/
        letter-spacing: 1px;
        height: 2em;
        line-height: 1em;
    }

        .work-desc {
            font-family: Fira Sans, SourceSansPro;
            font-size: 14px;
            line-height: 1.25;
            max-width: 300px;
            margin: 7px 0 0 10px;
        }

    /** Category bar */
    #category-bar {
        background-color: #eee;
        z-index: 1;
        width: 100%;
        height: 72px;
        line-height: 48px;
        position: fixed;
        top: 50px;
        /* margin-top: 150px; */
    }

        #category-bar ul {
            height: inherit;
            line-height: 48px;
            width: inherit;
            vertical-align: middle;
            display: inline-block;
            text-align: center;
        }

            /* Category buttons */
            nav#category-bar ul li {
                /* Override "nav ul li" rules */
                color: #000000;
                font-family: "SourceSansPro";
                font-size: 1em;
                font-weight: bold;
                text-transform: uppercase;
                text-indent: 0px;
                letter-spacing: 1px;
                line-height: 16px;
                height: inherit;

                /** The rest */
                display: inline-block;
                list-style: none;
                text-align: center;
                vertical-align: middle;
                padding: 0;
                /* margin: 0 20px; */
                width: initial;

                /** Prevent li background from overflowing*/
                /* margin-top: 0; */
            }

                nav#category-bar ul li a {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    height: inherit;
                    width: inherit;
                    /* height: inherit; */
                    padding: 0 20px;

                }

                    nav#category-bar ul li a:hover {
                        background-color: #e0e0e0;
                        border: none;
                    }

    /** Banner */
    div#wk_header_wrapper {
        margin-top: 0;
        width: 100%;
        text-align: center;
        background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url('../../assets/images/laptop_discussion.jpg');
        background-attachment: fixed;
        background-position-x: 100%;
        background-position-y: 150%;
    }
   

        div#wk_header {
            font-family: "FiraSans";
            /* padding: 70px 0; */
            padding: 70px 0;
        }

            div#wk_header > h1 {
                font-size: 3em;
                display: inline-block;
                letter-spacing: 0.1em;
                margin: 0 0 24px 0;
                /* height: 100%; */
            }

            div#wk_header > p {
                font-size: 18px;
                margin: 0 auto;
                width: 700px;
            }
    /* Allow the elements to be in line with each other */
    .lineup {
        display: inline-block;
        vertical-align: middle;
        text-align: initial;
    }

    p.work-desc {
        text-align: left;
    }
}

/* General */

/** Our Works */
.our-works-wrapper {
    margin-top: 20px;
}

/** Titles */
.section-title, .section-subtitle {
    display: inline-block;
}

div.title-subtitle-wrapper {
    margin-left: 14px;
}

.section-number {
    text-align: right;
}

a.anchor {
    display: block;
    position: relative;
    top: -200px;
    visibility: hidden;
}

/* Overrides .content-title -> for re-adjusting indent */

/* .service-title {
    font-family: FiraSans, SourceSansPro;
    font-size: 20px;
    text-transform: uppercase;
    margin: 0 0 0 10px;
    text-indent: 3px;
    letter-spacing: 3px;
} */

/* .work-desc {
    font-family: Fira Sans, SourceSansPro;
    font-size: 14px;
    line-height: 1.25;
    max-width: 300px;
} */

.is-current {
    background-color: #cccccc;
}

div#works-wrapper {
    overflow: visible;
    margin-top: 70px;
}
div#works-wrapper1 {
    overflow: visible;
    margin-top: 0px;
}
#paragraph1{
    text-align:center;
    font-family: Fira Sans, SourceSansPro;
    font-size: 3em;
    line-height: 1.25;

}
.image-gallery{
    width:100%;
    margin:0 auto;
}
.image-gallery-slide img {
    width: 100%;
    height: 55vh;
}
.image-gallery-description {
    font-size:180%;
    position: absolute;
    height:6%;
    top:0px;
    width: 100%;
    text-align:center;
    padding: 10px 0px 10px 0px;
}
.image-gallery-button {
    font-size:180%;
    position: absolute;
    height:6%;
    bottom:0px;
    width: 100%;
    text-align:center;
    padding: 10px 0px 10px 0px;
}
.usecaseheader{
    text-align:center;
    font-size:2em;
    margin:70px;
    /*font-family: "Times New Roman", Times, serif;*/
    
    padding:auto;
    color:#8B0000;
}
.usecaseheader1{
    text-align:center;
    font-size:2em;
    margin:70px;
    
    padding:auto;
    color:#0a539d;;
}
.carouselfeature{
    margin-right:1%;
}






/* .image-gallery-right-nav::before{
    background-color: red;
}
.image-gallery-left-nav::before{
    background-color: red;
} */