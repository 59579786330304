@media (max-width: 768px) and (min-width: 426px) {
  .box {
    width: 40vw;
  }

  .partner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50vw !important;
    height: auto;
  }

  .client-content-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 30px;
    /* padding-right: 10px; */
  }

  .name {
    /* margin: auto !important; */
  }
}

@media (min-width: 426px) {
  /* .home-partner-img {
    width: 50%;
  } */
}

@media (max-width: 425px) {
  .home-partner-img {
    width: 72%;
  }
}

/** */

/* #partners {
  min-height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 80px 0 0 0;
  opacity: 1;
}

.vertical-align-middle {
  vertical-align: middle;
  align-content: center;
  align-items: center;
}

#container_partners {
  max-width: none;
} */