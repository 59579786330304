@media (max-width: 1023px) {  

  #contact-email {
    font-size: 0.85em;
    letter-spacing: 1px;
  }

  .contact-details {
    margin: auto;
    width: 100%;
  }

  #contact-email-content {
    display: inline-block;
    margin: auto;
  }

  img.contact-icon {
    display: inline-block;
  }
}

@media (min-width: 1024px) {

  #contact-email {
    font-size: 0.95em;
    letter-spacing: 1px;
  }

}

/** Hide border-right line */
#contact-details {
  border: none;
}

.contact-subtitle {
  
    width: 185px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
   
}


/* section#home-contact  */

#contact h2 {
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: .5714em;
}
.contact-page-icon {
    height: 25px;
}

/** Bottom details */

/** Align mail icon with address */
#contact-detail-icon {
  display: inline-block;
}

@media (max-width: 425px) {
  div#map_wrapper {
    align-items: center;
    display: block;
    margin-bottom: 10px;
    /* width: 100%; */
  }

    #contact-details {
      border: none;
    }

    div#contact_map, div#map_desc {
      display: block;
    }

    div#map_desc {
      padding: 0 20px;
    }

    div#contact_map {
      height: 200px;
      padding: 0 20px;
    }

    section#contact {
      padding-left: 25px;
    }
}

@media (min-width: 426px) {
  div#map_wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    /* width: 100%; */
  }

    div#contact_map, div#map_desc {
        display: inline-block;
    }

    div#contact_map {
      height: auto;
      padding: 0;
    }

    div#map_desc {
        vertical-align: middle;
        padding: 0 20px;
    }

        /* div#map_desc > h3 {
            font-size: 20px;
            letter-spacing: 2px;
            font-weight: 600;
        } */

        div#map_desc > p, div#map_desc > p > span {
            font-size: 0.95em;
            letter-spacing: 1px;
        }

    /** Keep section no. row in line */
    section#contact > div.contact-content {
        padding-top: 0;
    }

  /* #contact-details h2, #social-details h2 {
    display: block;
  } */
}

@media (min-width: 768px) {
  section#contact {
    padding-left: 35px;
  }
}

@media (max-width: 425px) {
  p.locations {
    font-size: 15px
  }

  span.locations {
    font-size: 15px
  }
}