section#approach {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.45)
    ),
    url("../../assets/images/home-office_bright.jpg");
  background-attachment: fixed;
  background-size:cover;
  
  width:100%;
 

  
  /* background: #0f0; */
}

div#approach-wrapper {
 
  padding-top:1%;
  padding-bottom:1%;
  background: linear-gradient(
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.9)
  );
}

section#landing .react-reveal h1.subtitle {
  font-weight: normal;
}

section#landing .react-reveal h1.title {
  font-size: 2.5rem;
}

