.column-testimonial {
  background: rgba(255, 255, 255, 0.8);
}

#testimonials .hero-body .react-reveal {
  margin: auto;
}

#testimonials {
  min-height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 80px 0 0 0;
  opacity: 1;
  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.35)), url('./../../../assets/images/Lukas\ added\ to\ photo\ yaacoobIbrahim.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  align-content: center;
}

.testimonial-title {
    font-family: FiraSans, SourceSansPro;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 4px;
    padding: 10px 20px 10px 20px;
    border: 1px solid #000;
}

.testimonial-para {
  font-family: FiraSans-Light, SourceSansPro-Light;
  margin-top: 25px;
  padding-left: 25px;
  border-left: 2px solid #000;
}