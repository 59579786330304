#landing {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../../assets/images/bg-stock.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  align-content: center;
  overflow: hidden;
}

@media (max-width: 768px) {
  #landing h1 {
    font-size: 1.5em;
  }
}

@media (max-width: 375px) {
   .title {
    width:100vw;
  }
}

