@import url("fonts.css");

html {
  height: 100%;
  width: 100%;
}

body {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: FiraSans, SourceSansPro, sans-serif !important;
  background-color: "light";
  overflow-x: hidden;
}
/* 
@media (max-width: 768px) {
  .hero-body {
    padding: 10px !important;
  }
} */
