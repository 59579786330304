* {
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
}

#app .hero-body {
  padding-top: 30px;
  padding-left:0px;
  padding-right:0px;
  justify-content: center;
}

/**************************************************************/

/************************* T E X T S **************************/

/**************************************************************/

.title {
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
  font-size: 72px;
  text-transform: uppercase;
  text-indent: 25px;
  letter-spacing: 25px;
}

@media only screen and (max-width: 426px) {
  .title {
    font-size: 36px;
  }
}

.subtitle {
  font-family: FiraSans, SourceSansPro-Semibold;
  font-size: 24px;
  text-transform: uppercase;
  text-indent: 25px;
  letter-spacing: 10px;
}

.subtitle-light {
  font-family: FiraSans, SourceSansPro;
  font-size: 16px;
  text-transform: uppercase;
  text-indent: 5px;
  letter-spacing: 5px;
}

.section-h2 {
  line-height: 0.7;
  padding: 10px 10px 10px 0;
}

.section-title {
  font-family: FiraSans-Semibold, SourceSansPro-Semibold;
  font-size: 20px;
  text-transform: uppercase;
  text-indent: 5px;
  letter-spacing: 5px;
}

.section-subtitle {
  font-family: FiraSans, SourceSansPro;
  font-size: 16px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
}

.section-number {
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
  font-size: 48px;
  text-transform: uppercase;
  letter-spacing: 15px;
}

.content-quote {
  font-family: FiraSans-Medium;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.content-title-light {
  font-family: FiraSans-Light, SourceSansPro-Light;
  font-size: 20px;
  text-transform: uppercase;
  text-indent: 5px;
  letter-spacing: 5px;
}

.content-title {
  font-family: FiraSans, SourceSansPro;
  font-size: 20px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
}

.content-para {
  font-family: Fira Sans, SourceSansPro;
  font-size: 14px;
  line-height: 2;
}

.content-para-light {
  font-family: FiraSans-Light, SourceSansPro-Light;
  font-size: 14px;
  text-transform: uppercase;
  text-indent: 5px;
  letter-spacing: 5px;
}

.content-para-16px {
  font-family: Fira Sans, SourceSansPro;
  font-size: 16px;
  line-height: 2;
}

.content-para-20px {
  font-family: Fira Sans, SourceSansPro;
  font-size: 20px;
  line-height: 2;
}

.button-link {
  color: #000;
  font-family: FiraSans, SourceSansPro;
  font-size: 18px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
  background-color: #f5f5f5;
  border: 1px solid #000;
  padding: 10px;
}

.button-link:hover {
  color: #000;
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
  text-decoration: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(37, 37, 37, 0.6);
  text-decoration: none;
}

.page-title-medium {
  font-family: FiraSans-Medium, SourceSansPro-Semibold;
  font-size: 24px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
}

.page-title-reg {
  font-family: FiraSans, SourceSansPro;
  font-size: 24px;
  text-transform: uppercase;
  text-indent: 3px;
  letter-spacing: 3px;
}

a,
a:hover {
  color: #000;
  text-decoration: none;
}

div#app-container {
  margin: 0;
  padding: 0;
  width: 100%;
}
