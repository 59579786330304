/* .navbar{
  height: 40px;
} */


.navbar::after {
  content: "";
  display: block;
  height: 3px;
  background: #6381bb; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #6381bb 0%,
    #c9527a 50%,
    #954791 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #6381bb 0%,
    #c9527a 50%,
    #954791 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #6381bb 0%,
    #c9527a 50%,
    #954791 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6381bb', endColorstr='#954791',GradientType=1 ); /* IE6-9 */
}
.hero-head{
  
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 9999;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.navbar-item img {
  
  /* margin-left:-20%; */
 
  /* max-height: auto; */
  
}
.navbar-item{
  padding:0px 40px;
  
}
.underline1:hover{
  color: #4a4a4a;
 border-bottom:2px #4a4a4a solid;
}

/* .navbar-item > img {
  
} */
.dropdown {
  box-shadow: 0 0 8px #777;
  display: none ;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 1000;
}

.is-open {
  display: block;
}

@media (max-width: 768px){
  .navbar-item {
    padding-left: 20px;
  }
}

@media (max-width: 425px){
  .navbar-item {
    padding-left: 10px;
  }
}