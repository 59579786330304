@media(max-width:800px){
    .image-gallery-slide img{
        width:70vw;
    }
}

@media (max-width: 1024px){
    /* descriptions */
    .content-para {
        font-size: 13px !important
    }

    /* iphone picture */
    #iphonemac {
        width: 55% !important
    }
}

@media (max-width: 768px) {
    /* bus and handshake pics */
    #picture {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    
    /* heading */
    .content-title-light {
        padding-left: 25px !important;
    }

    /* description */
    .content-para {
        padding-left: 30px !important;
        padding-right: 30px !important;
        font-size: 15px !important
    }

    #iphonemac {
        /* display: flex; */
        display: block;
        margin-left: auto;
        margin-right: auto;
        /* width: 10%; */
        /* padding-left: 80px */
    }

    /* #iphone {
        width: 80%;
        height: auto;
    }

    #mac {
        width: 80%;
        height: auto;
    }  */

    /* #iphonepic {
        height: auto;
        width: auto
    }

    #macpic {
        height: auto;
        width: auto
    } */

    /* heading */
    .ccol-md-11 {
        text-align: center
    }
    .content-title-light {
        text-align: center
    }

    /* features description */
    p#desc {
        padding-left: 30px
    }
    p.content-para {
        padding-left: 30px
    }
}

@media (max-width: 425px) {
    #iphonemac {
        display: block;
        margin-left: auto;
        margin-right: auto;
        /* width: 50%; */
        padding-left: 30px;
        /* padding-right: 2px; */
    }

    /* #iphone {
        padding-right: 30px;
    }

    #mac {
        padding-right: 30px;
    } */

}

@media (max-width: 375px) {
    #iphone {
        padding-right: 30px;
    }

    #mac {
        padding-right: 30px;
    }
}