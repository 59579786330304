/** SERVICES OFFERED wrapper*/
#header_wrapper {
    background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.75)), url('../../../assets/images/ServicesIntro.jpg');
	background-attachment: fixed;
}

div.panel-content {
    /* width: 100%; */
}

/** Translucent white background */
/* #header_alpha_bg {
    background-color: #fff;
    opacity: 0.7;
} */

#header_intro {
    padding: 70px 0;
    text-align: center;
    font-family: SourceSansPro;

    /** Space main content away from navbar */
    margin: 0 auto;
}

    #header_intro h1 {
        display: inline-block;
        margin: 0 0 0.5em 0;
    }

    #header_intro p {
        font-size: 18px;
    }

/** Wrapper for title and subtitle */
.title-wrapper {
    text-align: center;
    margin: 0 auto;
}

/** Titles formatting */
.panel-right h4, .panel-left h4, .title-big {
    font-family: "FiraSans";
    letter-spacing: 0.1em;
}

    /** Big title */
    h1.title-big {
        font-size: 3em;
        display: inline;
        /* height: 100%; */
    }

    /** Subtitle */
    h4.subtitle {
        display: inline;
        letter-spacing: 0.1em;
    }

/** Mobile S -- 320px *//* CURRENTLY FIXING */
@media (min-width: 320px) {
    #header_intro {
        max-width: 320px;
    }

    #header_intro p {
        margin: 0 20px;
    }

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 2.5em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 18px;
            font-weight: bold;
            /* display: flex; */
            /* justify-content: space-between */
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 1.05em;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
            line-height: 1.25;  /* 1.25*/
            max-width: 260px;   /*300px*/
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        /* border: 1px solid #aaa; */
        border-bottom: 2px solid #888;
        margin: 0;
    }

    .service-row {
        height: 540px;
        /* width: 425px; */
        width: 100%;
        margin: 0 auto;
        display: block;
        padding: 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            padding: 10px 10px;
            display: block;
            /* justify-content: space-between; */
            width: 320px;
            height: 180px;
            overflow: hidden;
        }

        .service-panel-last {
            border: none;
        }

        .service-panel {
            border-bottom: 1px solid #aaa;
        }

            .panel-content {
                display: inline-block;
                margin: 0 auto;
            }

            .panel-left {
                width: 30px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 270px;
                height: 150px;
                overflow: hidden;
            }
}

/** Mobile M -- 375px *//* CURRENTLY FIXING */
@media (min-width: 375px) {
    #header_intro {
        max-width: 320px;
    }

    /* p {
        font-size: 14px;
    } */

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 3em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 21px;
            font-weight: bold;
            /* display: flex; */
            /* justify-content: space-between */
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 21px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 1.25em;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
            line-height: 1.25;  /* 1.25*/
            max-width: 350px;   /*300px*/
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        /* border: 1px solid #aaa; */
        border-bottom: 2px solid #888;
        margin: 0;
    }

    .service-row {
        height: 540px;
        /* width: 425px; */
        width: 100%;
        margin: 0 auto;
        display: block;
        padding: 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            padding: 10px 0;
            display: block;
            /* justify-content: space-between; */
            width: 320px;
            height: 180px;
            overflow: hidden;
        }

        .service-panel-last {
            border: none;
        }

        .service-panel {
            border-bottom: 1px solid #aaa;
        }

            .panel-content {
                display: inline-block;
                margin: 0 auto;
            }

            .panel-left {
                width: 30px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 290px;
                height: 150px;
                overflow: hidden;
            }
}

/** Mobile L -- 425px */
@media (min-width: 425px) {
    #header_intro {
        max-width: 350px;
    }

    /* p {
        font-size: 14px;
    } */

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 3em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 21px;
            font-weight: bold;
            /* display: flex; */
            /* justify-content: space-between */
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 21px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 1.25em;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
            line-height: 1.25;  /* 1.25*/
            max-width: 350px;   /*300px*/
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        /* border: 1px solid #aaa; */
        border-bottom: 2px solid #888;
        margin: 0;
    }

    .service-row {
        height: 540px;
        /* width: 425px; */
        width: 100%;
        margin: 0 auto;
        display: block;
        padding: 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            padding: 15px;
            display: block;
            /* justify-content: space-between; */
            width: 400px;
            height: 180px;
        }

        .service-panel-last {
            border: none;
        }

        .service-panel {
            border-bottom: 1px solid #aaa;
        }

            .panel-content {
                display: inline-block;
                margin: 0 auto;
            }

            .panel-left {
                width: 40px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 330px;
                height: 140px;
                overflow: hidden;
            }
}

/** Tablet -- 768px   */
@media (min-width: 768px) {
    
    #header_intro {
        max-width: 700px;
    }

    /* p {
        font-size: 14px;
    } */

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 3em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 18px;
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 18px;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 15px;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
        }

            /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        border: 1px solid #aaa;
        margin: 0 0 5px 0;
    }

    .service-row {
        height: 240px;
        width: 750px;
        margin: 0 auto;
        display: flex;
        padding: 20px 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            /* display: flex; */
            /* justify-content: space-between; */
            padding: 8px;
            /* display: initial; */
            width: 200px;
            height: 200px;
            border: none;
        }

            .panel-content {
                display: inline-block;
                margin: 0px;
                overflow: hidden;
            }

            .panel-left {
                width: 40px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 200px;
                height: 130px;
            }
}

/** iPad Pro -- 1024px */
@media (min-width: 1024px) {

    p.service-desc {
        font-size: 0.9em;
        margin: 0;
    }

    /** Titles formatting */
    .panel-right h4, .panel-left h4, .title-big {
        font-family: "FiraSans";
        letter-spacing: 0.1em;
    }

        /** Big title */
        h1.title-big {
            font-size: 3em;
            display: inline;
            /* height: 100%; */
        }

        /** Subtitle */
        h4.subtitle {
            display: inline;
            letter-spacing: 0.1em;
        }

        h4.service-no {
            font-size: 20px;
        }

        /** Title of each service */
        h4.service-title {  /** Overriding ".service-title" rule (where is it coming from?) */
            font-size: 20px;
            text-transform: uppercase;
            margin: 0;
            text-indent: 0;
            letter-spacing: 1px;
        }

        p.service-desc {
            font-size: 16px;
            margin: 0;
            font-family: FiraSans, SourceSansPro, sans-serif;
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        border: 1px solid #aaa;
        margin: 0 0 5px 0;
    }

    .service-row {
        height: 240px;
        width: 900px;
        /* display: flex; */
        /* justify-content: space-between; */
        margin: 0 auto;
        padding: 20px 0;
    }

        .service-panel, .service-panel-last {
            margin: 0 auto;
            width: 270px;
            height: 200px;
            border: none;
        }

            .panel-content {
                display: inline-block;
                margin: 0px;
            }

            .panel-left {
                width: 50px;
                height: 100px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 190px;
                height: 180px;
                overflow: hidden;
            }

            .panel-right h4 {
                height: 2em;
                margin-bottom: 20px;
                /* letter-spacing: 0; */
                line-height: 1em;
            }
}

@media (min-width: 1440px) {

        p.service-desc {
            font-size: 0.9em;
            margin: 0;
        }

    /** Each row of services */
    .row-wrapper {
        background: #fff;
        /* padding: 10px 0; */
        border: 1px solid #aaa;
        margin: 0 0 5px 0;
    }

    .service-row {
        height: 240px;
        width: 1300px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 20px 0;
    }

        .service-panel, .service-panel-last {
            width: 400px;
            height: 250px;
        }

            .panel-content {
                display: inline-block;
                margin: 0px;
            }

            .panel-left {
                width: 50px;
                height: 100px;
                line-height: 1;
                vertical-align: top;
            }

            .panel-right {
                width: 300px;
                height: 200px;
                overflow: hidden;
            }
}


.service-row {
    /* height: 240px; */
    /* display: flex; */
    /* justify-content: space-between; */
    /* margin: 0 auto; */
    /* padding: 20px 0; */
}

    .service-panel {
        /* width: 400px;
        height: 250px; */
    }
    
        .panel-content {
            /* display: inline-block;
            margin: 0px; */
        }

        /* Panel */
        .panel-left {
            /* width: 50px;
            height: 100px;
            line-height: 1;
            vertical-align: top; */
        }

        .panel-right {
            /* width: 300px;
            height: 200px;
            overflow: hidden; */
        }
    
div div #services { /** Override inline style*/
    width: 100%;
    /* min-height: 100vh; */
    padding: 50px 0 70px 0; /* Header intro padding - service row margin-bottom */
}

#container_tech {
    min-height: 0;
}

/* Overrides 1200px max-width*/
@media (min-width: 1400px) {#services .container {
    /* max-width: 1300px; */
}}

/* .service-desc {
    margin-top: 2rem;
}

.service-item {
    margin-top: 1.3rem;
} */

.service-number {
    font-family: FiraSans-Medium, SourceSansPro-Semibold, sans-serif;
    font-size: 20px;
    text-indent: 5px;
    letter-spacing: 5px;
}

.service-name {
    font-family: FiraSans, SourceSansPro, sans-serif;
    font-size: 20px;
    text-indent: 5px;
    letter-spacing: 5px;
    border-bottom: 1px solid #000;
}

a {
    color: black;
    text-decoration: none;
    cursor: pointer;
    transition: color .4s linear;
}

a:hover {
    text-decoration: none;
    color: rgba(0, 1, 5, 0.568);
}

@media (min-width: 1200px) {.placeholder {
    background-color: #555;
    width: 176px;
    height: 99px;
}}

@media (min-width: 1400px) {.placeholder {
    background-color: #555;
    width: 224px;
    height: 126px;
}}

div.tech-row > div.tile {   
    background-color: #f7f7f7;
    border-radius: 5px;
}

div.tile > img {
    display: block;
    margin: auto;
    vertical-align: middle;
}

#works-div-render {
    background: #F5F5F5;
}

.pointer {cursor: pointer;}

@media (max-width: 1024px) {
    /* nav bar links */
    nav#category-bar {
        display: none;
    }

    /* containers */
    div.container {
        padding-left: 35px;
        padding-right: 35px;
    }

    /* resize images */
    img.icon {
        width: 200px;
    }
}

/* mobile */
@media (max-width: 425px) {
    div.our-works-wrapper {
        
    }
}