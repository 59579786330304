.para1{


        position: relative;
        top: 10em;
        text-align:center;
        z-index: 1;
      
}
.containerusecase:hover{
    opacity:0.2;
    
}
.para2{
    margin-bottom:10%;
    margin-top:4%;
    font-size:16px;
}
.featureHeader{
    position: relative;
        top: 10em;
        text-align:center;
        z-index: 1;
        margin:0;
}