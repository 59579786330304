@font-face{
    font-family: SourceSansPro-Light;
    src: url('assets/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf');
}

@font-face{
    font-family: SourceSansPro;
    src: url('assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf');
}

@font-face{
    font-family: SourceSansPro-Semibold;
    src: url('assets/fonts/Source_Sans_Pro/SourceSansPro-Semibold.ttf');
}

@font-face{
    font-family: SourceSansPro-Bold;
    src: url('assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf');
}

@font-face{
    font-family: SourceSansPro-Black;
    src: url('assets/fonts/Source_Sans_Pro/SourceSansPro-Black.ttf');
}

@font-face{
    font-family: FiraSans-Light;
    src: url('assets/fonts/Fira_Sans/FiraSans-Light.ttf');
}

@font-face{
    font-family: FiraSans;
    src: url('assets/fonts/Fira_Sans/FiraSans-Regular.ttf');
}

@font-face{
    font-family: FiraSans-Medium;
    src: url('assets/fonts/Fira_Sans/FiraSans-Medium.ttf');
}

@font-face{
    font-family: FiraSans-Semibold;
    src: url('assets/fonts/Fira_Sans/FiraSans-SemiBold.ttf');
}

@font-face{
    font-family: FiraSans-Bold;
    src: url('assets/fonts/Fira_Sans/FiraSans-Bold.ttf');
}

@font-face{
    font-family: FiraSans-Black;
    src: url('assets/fonts/Fira_Sans/FiraSans-Black.ttf');
}